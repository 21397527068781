.box {
  position: relative;
  display: inline-block;
}

.boxForm {
  width: 100%;
}

.calendarBox {
  position: absolute;
  z-index: 999;
  top: 3.8rem;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0.8px 3.6px #aaa;
}

.inputBox {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  width: 250px;
  padding: 0.8rem 1.6rem;
  font-size: 1rem;
  font-family: 'Roboto';
  text-align: center;
  color: #666;
}

.inputBox:focus {
  outline: none;
}

.calendarBox {
  position: absolute;
  z-index: 999;
  top: 3.6rem;
  transform: translateX(-50%);
  box-shadow: 0 0.8px 3.6px #aaa;
}

.calendarBox__center {
  left: 50%;
}

.calendarBox__left {
  left: 66%;
}

.calendarBox__right {
  left: 34%;
}

@media (max-width: 900px) {
  .calendarBox__left {
    left: 50%;
  }

  .calendarBox__right {
    left: 50%;
  }
}
